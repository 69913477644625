import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'redux/store';
import Providers from 'context/Providers';
import './main.css';
import './index.css';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import AppRoutes from './App';
import reportWebVitals from './reportWebVitals';
const TypedProvider: any = Provider;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <TypedProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Providers>
          <AppRoutes />
        </Providers>
      </PersistGate>
    </TypedProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
