import React, { useEffect, useState } from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import bookingService from 'services/bookingService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SuccessPayment from '../../components/common/Modal/SuccessPayment';
import { useToast } from 'components/common/Toast/ToastProvider';
import { RootState } from 'redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AuxService } from 'services/auxService';
import { Radio } from 'antd';
import SelectPaymentType from 'components/common/Modal/selectPaymentType';
import BankTransfer from 'components/common/Modal/bankTransfer';

export default function Coupon({
    setPaymentForm,
    payment,
    isLoading,
    paramBookingRef,
    paramBookingId,
    detailID,
    paramQty,
    getBookingByBookingId,
    setQuotes,
    installmentNo,
    locationStates,
    isExperience,
    artdata,
    checkoutdetails, 
    deliveryData,
    productDetails,
    setproductDetails,
    setIsLoyalityCodeApplied,
    setIsDiscountCodeApplied,
    setIsLoyaltypayment,
    isLoyaltyPayment,
    setSeerbitloading,
    seerbitloading,
    bankDetails,
    confirmDirectBankTransfer,
    isConfirmLoading,
    setIsConfirmLoading
}: any) {
    const { detailsID } = useParams<{ detailsID: string }>();
    const constID = detailsID
    // const { installmentType } = useParams<{installmentType : string}>()
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openModal, setOpenModal] = useState(false);
    const [openPaymentMethodModal, setOpenPaymentMethodModal] = useState(false);
    const [openBankTransferModal, setOpenBankTransferModal] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [discountApplied, setDiscountApplied] = useState(false)
    const [loyalityApplied, setLoayalityApplied] = useState(false)
    const [balAfterApplied, setBalAfterApplied] = useState(0)
    const [discountApplying, setDiscountApplying] = useState(false)
    
    const [insuranceList , setInsuranceList] = useState([]);

    const installmentType = searchParams.get('installmentType');
    const installmentNum = searchParams.get('installmentNo');
    const cancelled = searchParams.get('message');

    const toast = useToast();
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const { selectedBooking } = useSelector((state: RootState) => state.reservation);

    const [ isLoyaltyPaymentAmount, setIsLoyaltypaymentAmount ] = useState(0);
    const [acceptTerms, setAcceptTerms] = useState('')

    // flutterwave config
    const public_key = process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY || '';
    
    const config = {
        public_key,
        tx_ref: Date.now().toString(),
        amount: getAmount().toFixed(2),
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: payment.userData.email || localStorage.getItem('guestEmail'),
            phone_number: payment.userData.phone || localStorage.getItem('guestPhone'),
            name: (payment.userData.firstName || localStorage.getItem('guestFirstName')) + ' ' + (payment.userData.lastName || localStorage.getItem('guestLastName')),
        },
        customizations: {
            title: 'Reservation',
            description: 'Payment for reservation',
            logo: '',
        },
    };

    function getAmount() {
        if (payment?.paymentType == "full"){
            if(discountApplied && loyalityApplied){
                return balAfterApplied
            }else if(discountApplied){
                return payment.totalPayable;
            }else if(loyalityApplied){
                return balAfterApplied;
            }else if (artdata && artdata.isAuxservice) {
                setPaymentForm({
                    ...payment,
                    totalPayable: checkoutdetails?.totalAmount  || 0
                })
                return checkoutdetails?.totalAmount  || 0;
            } else {
                return payment.totalPayable;
            }
        }
        if (!isNaN(isLoyaltyPaymentAmount) && isLoyaltyPaymentAmount) {
            // return isLoyaltyPaymentAmount;
            return payment.totalPayable;
        } 
        else if (!isNaN(payment.totalPayable) && payment.totalPayable) {
            return payment.totalPayable;
        } 
        else if (artdata && artdata.isAuxservice) {
            setPaymentForm({
                ...payment,
                totalPayable: checkoutdetails?.totalAmount  || 0
            })
            return checkoutdetails?.totalAmount  || 0;
        } else {
            return 0;
        }
    }

    // seerbit config checkout-complete
    const callbackUrl = window.location.origin + window.location.pathname +
        `${detailID ? '?detailID=' + detailID : ''}` +
        `${paramQty ? '?qty=' + paramQty : ''}` +
        `${installmentNo ? '?installmentNo=' + installmentNo : ''}` +
        `${installmentNo ? '&installmentType=' + payment?.paymentType : ''}`
    // + `${payment?.isActivityCalender ? '&activity=' + paramBookingId : ''}`

    const seerbit_config = {
        amount: getAmount().toFixed(2),
        currency: 'NGN',
        country: 'NG',
        callbackUrl,
        // callbackUrl: window.location.origin + "checkout-complete",
        paymentReference: Date.now().toString(),
        email: payment.userData.email || localStorage.getItem('guestEmail'),
        bookingId: paramBookingId ? paramBookingId : detailsID ? detailsID?.toString() : 0,
        metaData: paramBookingId ? paramBookingId : detailsID ? detailsID?.toString() : 0,
    };

    const navigate = useNavigate();
    const [seerbitCheckout, setSeerbitCheckout] = useState<any>();

    const handleFlutterPayment = useFlutterwave(config);

    const payWithRedpay = async () => {
        if (!window.RedPayPop) {
          return
        }

        try {
          let handler = await window.RedPayPop.setup({
            key: "TPK_BEDFDB10250C9A8DF45C20250319110107", // Test key
            // key: "PK_A5B84429D5F3F20EFA9B20250319110107", //Live Key
            amount: getAmount().toFixed(2) * 100,
            // amount: 100 * 100,
            email: payment.userData.email || localStorage.getItem('guestEmail'),
            currency: "NGN",
            channels: ["CARD", "QR", "USSD", "TRANSFER", "GOOGLE_PAY", "APPLE_PAY"],
            ref: `REF-${Math.ceil(Math.random() * 10e10)}`,
            onClose: function () {
              console.log("Window closed.");
            },
            callback: function (response: any) {
                console.log("redpay payment call back", response);
                redPayCallback(response)
            },
            onError: function (error: any) {
            //   console.log("error", error);
            },
            env: 'test'
          });
          await handler.openIframe();
        } catch (err) {
        //   console.log("érrr", err);
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (payment.paymentPlatform === 'flutterwave') {
            // console.log( payment,config);
            handleFlutterPayment({
                callback,
                onClose: () => { },
            });
        }

        if (payment.paymentPlatform == 'seerbit' && seerbitCheckout) {
            window.location.replace(seerbitCheckout?.payments?.redirectLink);
        }

        if (payment.paymentPlatform == 'redpay') {
            payWithRedpay();
        }
    };

    const callback = async (response: any) => {
        closePaymentModal();

        try {
            if (response.status === 'successful' || response.status === 'completed') {
                await verifyFlutterPayment({
                    transactionID: response.transaction_id.toString(),
                });
                return;
            }
            setPaymentSuccess(false);
            setOpenModal(true);
        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
        }
    };

    const redPayCallback = async (response: any) => {

        try {
            if (response.status === 'success' || response.status === 'completed') {
                await verifyRedPayPayment({
                    transactionID: response.reference.toString(),
                });
                return;
            }
            setPaymentSuccess(false);
            setOpenModal(true);
        } catch (err: any) {
            // console.log('err', err.response.data.responseMessage);
        }
    };

    const verifyRedPayPayment = async (params: any) => {
        setVerifyLoading(true);
        setOpenModal(true);
        try {

            if (path.includes('activity')) {
                await bookingService.verifyFlutterwavePaymentActivityCalender({
                    paymentReference: params?.transactionID,
                    bookingId: paramBookingId,
                })
                navigate('/checkout-complete/activity/' + detailID);
            } else {
                if(detailsID === constID && user.userID ){
                    const res = await bookingService.verifyRedPayment({
                        transactionID: params?.transactionID,
                        bookingID: detailsID,
                        paymentMethodType: payment?.paymentType,
                        installment: installmentNo
                    })
                    if(res.responseMessage == 'Ok' && constID === res?.data?.bookingID.toString()){
                        setTimeout(()=>{
                            window.location.href = `${window.location.origin}/checkout-complete/${constID}`;
                        },300)
                    }
                } else if(detailsID === constID && !user.userID){
                    const res = await bookingService.verifyGuestPaymentWithRedPay({
                        transactionID: params?.transactionID,
                        bookingID: detailsID,
                        paymentMethodType: payment?.paymentType,
                        installment: installmentNo
                    })
                    if(res.responseMessage == 'Ok' && constID === res?.data?.bookingID.toString()){
                        localStorage.removeItem('guestEmail')
                        localStorage.removeItem('guestPhone')
                        localStorage.removeItem('guestFirstName')
                        localStorage.removeItem('guestLastName')
                        setTimeout(()=>{
                            window.location.href = `${window.location.origin}/checkout-complete/${constID}`;
                        },300)
                    }
                }
        }

            // setVerifyLoading(false)
        } catch (err: any) {
            // console.log('err', err.response.data.responseMessage);
            setPaymentSuccess(false);
            setVerifyLoading(false);
        }
    };

    const verifyFlutterPayment = async (params: any) => {
        setVerifyLoading(true);
        setOpenModal(true);
        try {

            if (path.includes('activity')) {
                await bookingService.verifyFlutterwavePaymentActivityCalender({
                    paymentReference: params?.transactionID,
                    bookingId: paramBookingId,
                })
                navigate('/checkout-complete/activity/' + detailID);
            } else if (artdata && artdata.isAuxservice){
                const res = await AuxService.CreateOrderComplete({
                    cartIds: artdata.cartIds,
                    emailAddress: artdata.details.emailAddress,
                    firstName: artdata.details.firstName,
                    lastName: artdata.details.lastName,
                    phoneNumber: artdata.details.phoneNumber,
                    userId: artdata.userId,
                    provider: payment.paymentPlatform.toUpperCase(),
                    reference: params?.transactionID,
                    productid: artdata.productId || productDetails.productID,
                    deliveryAddress: deliveryData.address,
                    deliveryId: deliveryData.id,
                    deliveryType: deliveryData.type.toUpperCase(),
                })
                // console.log("comp", res);
                
                navigate('/checkout-complete/' + detailsID+'?orderId='+res.data);

            }
            else {
                if(detailsID === constID && user.userID ){
                    const res = await bookingService.verifyPayment({
                        transactionID: params?.transactionID,
                        bookingID: detailsID,
                        paymentMethodType: payment?.paymentType,
                        installment: installmentNo
                    })
                    if(res.responseMessage == 'Ok' && constID === res?.data?.bookingID.toString()){
                        setTimeout(()=>{
                        // navigate(`/checkout-complete/${constID}`);
                            window.location.href = `${window.location.origin}/checkout-complete/${constID}`;
                        },300)
                    }
                } else if(detailsID === constID && !user.userID){
                    const res = await bookingService.verifyGuestPayment({
                        transactionID: params?.transactionID,
                        bookingID: detailsID,
                        paymentMethodType: payment?.paymentType,
                        installment: installmentNo
                    })
                    if(res.responseMessage == 'Ok' && constID === res?.data?.bookingID.toString()){
                        localStorage.removeItem('guestEmail')
                        localStorage.removeItem('guestPhone')
                        localStorage.removeItem('guestFirstName')
                        localStorage.removeItem('guestLastName')
                        setTimeout(()=>{
                        // navigate(`/checkout-complete/${constID}`);
                            window.location.href = `${window.location.origin}/checkout-complete/${constID}`;
                        },300)
                    }
                }
        }

            // setVerifyLoading(false)
        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
            setPaymentSuccess(false);
            setVerifyLoading(false);
        }
    };


    const verifySeerbitPayment = async (ref: any) => {
        setVerifyLoading(true);
        setOpenModal(true);
        try {
            const res = {};
            if (seerbit_config.bookingId && user.userID) {

                const k = installmentNum?.replace('installment', '');
                k?.replace('full', '')

                await bookingService.verifySeerbitPayment({
                    "paymentReference": ref,
                    "bookingId": seerbit_config.bookingId,
                    paymentMethodMode: installmentType ? installmentType?.replace('?code=00', '') : payment?.paymentType,
                    installment: k ? k : installmentNo,
                    isLoyaltyPayment,
                });

                return navigate('/checkout-complete/' + detailsID);
            }else if (seerbit_config.bookingId && !user.userID) {

                const k = installmentNum?.replace('installment', '');
                k?.replace('full', '')

                await bookingService.verifySeerbitGuestPayment({
                    "paymentReference": ref,
                    "bookingId": seerbit_config.bookingId,
                    paymentMethodMode: installmentType ? installmentType?.replace('?code=00', '') : payment?.paymentType,
                    installment: k ? k : installmentNo,
                    isLoyaltyPayment,
                });
                        localStorage.removeItem('guestEmail')
                        localStorage.removeItem('guestPhone')
                        localStorage.removeItem('guestFirstName')
                        localStorage.removeItem('guestLastName')

                return navigate('/checkout-complete/' + detailsID); 
            } else if (artdata && artdata.isAuxservice){
                const res = await AuxService.CreateOrderComplete({
                    cartIds: artdata.cartIds,
                    emailAddress: artdata.details.emailAddress,
                    firstName: artdata.details.firstName,
                    lastName: artdata.details.lastName,
                    phoneNumber: artdata.details.phoneNumber,
                    userId: artdata.userId,
                    provider: payment.paymentPlatform.toUpperCase(),
                    reference: ref,
                    productid: artdata.productId,
                    deliveryAddress: deliveryData.address,
                    deliveryId: deliveryData.id,
                    deliveryType: deliveryData.type.toUpperCase(),
                })

                navigate('/checkout-complete/' + detailsID+'?orderId='+res.data);
            }
            else if (selectedBooking && selectedBooking?.bookingID) {
                await bookingService.verifySeerbitPaymentActivityCalender([ref, selectedBooking?.bookingID]);
                return navigate('/checkout-complete/activity/' + detailID);
            }
            else {
                await bookingService.verifySeerbitPaymentWithoutBookingId([ref]);
                navigate('/checkout-complete/activity/' + detailID);
            }

        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
            setPaymentSuccess(false);
            setVerifyLoading(false);
        }
    };

    const initializeSeerbitPayment = async () => {
        setSeerbitloading(true);
        try {
            let data;
            if (path.includes('activity')) {
                const res = await bookingService.initializeSeerbitPaymentActivityCalender(seerbit_config);
                setSeerbitCheckout(res?.data?.data);
            }
            else {
                if(user.userID){
                const res = await bookingService.initializeSeerbitPayment(seerbit_config);
                setSeerbitCheckout(res?.data);
                }else{
                    const res = await bookingService.initializeSeerbitGuestPayment(seerbit_config);
                setSeerbitCheckout(res?.data);
                }
            }

            setSeerbitloading(false);
        } catch (err: any) {
            setSeerbitloading(false);
            console.log('err', err.response.data.responseMessage);
        }
    };


    const applyCoupon = async (e: any) => {
        setDiscountApplying(true)
        e.preventDefault();
        if (payment?.couponCode.includes('C-')) {
            try {
                const res = await bookingService.validateCorporateCode({
                    "corporateCode": payment?.couponCode,
                    "bookingID": paramBookingId ? paramBookingId : detailsID,
                    "email": payment?.userData?.email || localStorage.getItem('guestEmail'),
                    isExperience,
                    isPointApplied: isLoyaltyPayment
                })

                setIsDiscountCodeApplied(true)
                setDiscountApplying(false)
                
                setDiscountApplied(res.data?.isDiscountCodeApplied)
                setLoayalityApplied(res.data?.isLoyaltyPointApplied)
                setBalAfterApplied(res.data?.costBreakDowns.filter((item)=> item.name == "Balance")[0].value)

                setproductDetails({
                    ...productDetails,
                    serviceModelRequests: res.data?.serviceModelRequests,
                    bookingDetails: res.data?.serviceModelRequests,
                })

                setPaymentForm({
                    ...payment,
                    totalPayable: res?.data?.totalPayable || payment?.totalPayable
                })

                setQuotes(res.data);
                return toast?.success("Code applied successfully.")
            }
            catch (err) {
                toast?.error('Invalid Corporate code.')
            }
        }
        else if (payment?.couponCode.includes('DIS-') || payment?.couponCode.includes('@')) {
            try {
                const res = await bookingService.validateDiscountCode({
                    "discountCode": payment?.couponCode,
                    "bookingID": paramBookingId ? paramBookingId : detailsID,
                    "email": payment?.userData?.email || localStorage.getItem('guestEmail'),
                    isExperience,
                    isPointApplied: isLoyaltyPayment
                })

                setIsDiscountCodeApplied(true)
                setDiscountApplying(false)
                
                setDiscountApplied(res.data?.isDiscountCodeApplied)
                setLoayalityApplied(res.data?.isLoyaltyPointApplied)
                setBalAfterApplied(res.data?.costBreakDowns.filter((item)=> item.name == "Balance")[0].value)

                setPaymentForm({
                    ...payment,
                    totalPayable: res?.data?.totalPayable || payment?.totalPayable
                })
                
                setQuotes(res.data);
                return toast?.success("Code applied successfully.")
            }
            catch (err) {
                toast?.error('Invalid Discount code.')
                setDiscountApplying(false)
            }
        }
        else {
            try {
                const res = await bookingService.applyReferralCode(
                    paramBookingId ? paramBookingId : detailsID,
                    payment?.couponCode,
                    payment?.totalPayable,
                )
                setDiscountApplying(false)
                return toast?.success("Code applied successfully.")

            }
            catch (err) {
                toast?.error('Invalid code.')
                setDiscountApplying(false)
            }

        }
    }


    const fetchInsuranceList = async ()=>{
        try {
            const res = await bookingService.getInsuranceList();
            setInsuranceList(res.data)
        }
        catch (err) {
            console.log('err ', err,)
        }      
    }

    const selectInsurance = async (id) =>{
        setSeerbitloading(true)
        try {
            const res = await bookingService.selectInsurance({
                "userId": user?.userID,
                "bookingID": paramBookingId ? paramBookingId : detailsID,
                "insuranceId": id,
                productName: await insuranceList.filter((p: any)=> p?.productId == id)?.[0]?.productName

            });
            
            let arr = await res.data?.costBreakDowns?.filter(p=> p?.name == "Total Amount Payable") || [];
            
            if(arr?.length > 0){
                payment.totalPayable = arr[0].value;

                setPaymentForm({
                    ...payment,
                    totalPayable: arr[0].value || payment?.totalPayable
                })

                setQuotes(res.data);
                setSeerbitloading(false);
                return toast?.success("Insurance applied successfully.")
            }
            
        }
        catch (err) {
            console.log('err ', err,);
            setSeerbitloading(false);
        }      
    }

    const checkoutWithLoyalty = async (e: any) => {
        e.preventDefault();
        setIsLoyalityCodeApplied(true)
        setIsLoyaltypayment(true)
        try {
            const res = await bookingService.checkoutWithLoyalty({
                "userId": user?.userID,
                "bookingID": paramBookingId ? paramBookingId : detailsID,
                "email": payment?.userData?.email,
                isExperience,
                discountCode: payment?.couponCode
            })

            let arr = await res.data?.costBreakDowns?.filter(p=> p?.name == 'Total Balance After Point') || [];
        
            if(arr?.length > 0){
                payment.totalPayable = arr[0].value;

                setIsLoyaltypaymentAmount(arr[0].value)

                setPaymentForm({
                    ...payment,
                    totalPayable: arr[0].value || payment?.totalPayable
                })
                setQuotes(res.data);

                setDiscountApplied(res.data?.isDiscountCodeApplied)
                setLoayalityApplied(res.data?.isLoyaltyPointApplied)
                setBalAfterApplied(res.data?.costBreakDowns.filter((item)=> item.name == "Balance")[0]?.value)

                return toast?.success("Loyalty point verified, please proceed to payment.")
            }

            if(res.data?.points == 'Completed'){

                if (path.includes('activity')) {
                    navigate('/checkout-complete/activity/' + detailID);
                } 
                // else if (artdata && artdata.isAuxservice){
                //     navigate('/checkout-complete/' + detailsID+'?orderId='+res.data);
                // }
                else {
                    navigate('/checkout-complete/' + detailsID);
                }

                return toast?.success("Booking successful.")
            }
            else{
               return toast?.error('Error occurred, please try again.')
            }
            
        }
        catch (err) {
            // console.log('err ', err,)
            toast?.error('Error occurred, please try again.')
        }
    }

    useEffect(() => {
        if (payment.paymentPlatform === 'seerbit') {
            initializeSeerbitPayment();
            return;
        }else{
            setSeerbitloading(false)
        }
    }, [
        payment.paymentPlatform, 
        payment.totalPayable, 
        isLoyaltyPayment
    ]);

    // useEffect(() => {
    //         initializeSeerbitPayment();
    // }, [
    //     isLoyaltyPayment
    // ]);

    useEffect(() => {
        fetchInsuranceList()
    }, []); 

    useEffect(() => {
        if (isLoading) return;
        const message = searchParams.get('message');
        if (message && message === '!success') {
            setPaymentSuccess(false);
            setOpenModal(true);
            return;
        }

        const ref = searchParams.get('reference');
        if (ref && message.includes('Successful')) {
            verifySeerbitPayment(ref);
        }
    }, []);

    useEffect(()=>{
        if(cancelled == "Transation cancelled"){
            window.location.href = window.location.origin + window.location.pathname
        }
    },[cancelled])

    const path = window.location.pathname.toLowerCase();

    return (
        <div className="mt-6">
            {
                path.includes('activity') || locationStates?.bookingId || !user.userID ? ''
                    : <p className=" text-2xl font-medium mb-4 ">Coupon</p>
            }

            <form>
                {
                    path.includes('activity') || locationStates?.bookingId ? ''
                        :
                        <div className="mb-6">
                            <label htmlFor="Display Name" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                                Referral Code / points / coupon
                            </label>

                            <div className='flex items-center justify-between w-full' >
                                <input
                                    type="text"
                                    id="couponCode"
                                    className="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg  block w-2/3 p-2.5 placeholder:text-sm placeholder:font-medium mr-5"
                                    placeholder="Enter coupon code"
                                    onChange={(e) =>
                                        setPaymentForm({
                                            ...payment,
                                            couponCode: e.target.value,
                                        })
                                    }
                                />
                                <button
                                    onClick={applyCoupon}
                                    disabled={payment?.couponCode?.length <= 0}
                                    className="py-2 rounded-md text-base font-normal  text-center w-1/3 bg-auraPrimary text-white hover:text-white  capitalize disabled:cursor-not-allowed disabled:bg-orange-300"
                                >
                                    {discountApplying ? 'Applying...' : 'Apply'}
                                </button>

                            </div>
                        </div>
                }
                {
                path.includes('activity') ? ''
                : 
                <div className="mb-6">
                    <label
                        htmlFor="Display Name"
                        className=" mb-2 text-xs font-bold uppercase flex items-center justify-between  text-neutral108"
                    >
                        <p>Insurance</p>
                        {/* <button
                            className=" underline text-auraPrimary "
                            type="button"
                            data-modal-toggle="insurancepackages-modal"
                        >
                            View Insurance Packages
                        </button> */}
                    </label>
                    <select
                        id="insuranceCode"
                        className="shadow-sm  border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 placeholder:text-sm placeholder:font-medium"
                        onChange={(e) =>{
                            setPaymentForm({
                                ...payment,
                                insuranceCode: e.target.value,
                                insuranceId: e.target.value,
                            });

                            // if(e.target.value){
                                selectInsurance(e.target.value);
                            // }
                        }}
                    >
                        <option value="0">None</option>
                        {
                            insuranceList.map((p: any)=>(
                            <option value={p?.productId}>{p?.productName || ''}</option>
                            ))
                        }
                        
                    </select>
                </div>
            }

               { user.userID && 
                    <div className="space-y-2 mb-5 mt-2">
                        <Radio.Group
                            onChange={checkoutWithLoyalty}
                            // value={paymentForm.paymentPlatform}
                        >
                            <Radio className="space-y-2 mb-5 mt-3 font-medium" value="checkOutWithLoyalty">Checkout with Loyalty Point</Radio>
                        </Radio.Group>
                    </div>
                }

                <div className="mb-6 mt-5 proceed-to-pay">
                    <button
                        type="button"
                        // onClick={()=>setOpenPaymentMethodModal(true)}
                        onClick={handleSubmit}
                        disabled={
                            (payment && payment.paymentType && payment.paymentPlatform && payment.totalPayable && !seerbitloading && acceptTerms)
                                ? (artdata && deliveryData?.type == "shipping") ?   !( deliveryData?.address?.length > 0 )
                                : false
                                : true
                        }
                        className=" py-3 rounded-xl text-base font-normal  text-center w-full bg-auraPrimary text-white hover:text-white  capitalize disabled:cursor-not-allowed disabled:bg-orange-300"
                    >
                        {seerbitloading ? 'Initializing' : 'Confirm and pay'}
                    </button>
                </div>

                <div className="mb-5">
                        <Radio.Group
                            onChange={(e)=> setAcceptTerms(e.target.value)}
                        >
                            <Radio className="space-y-2 mb-5 font-[400] text-[15px]" value="acceptterms">
                               <a href="/privacy-policy" target="_blank"> Guest agrees to the Payment and Cancellation Policy </a>
                            </Radio>
                        </Radio.Group>
                    </div>
            </form>

            {/* Select Payment Type Modal */}
            <SelectPaymentType
                openModal={openPaymentMethodModal}
                setOpenModal={setOpenPaymentMethodModal}
                handleSubmit={handleSubmit}
                verifyloading={verifyLoading}
                paymentSuccess={paymentSuccess}
                bankDetails={bankDetails}
                setOpenBankTransferModal={setOpenBankTransferModal}
            />

            {/* Bank Transfer Payment Modal */}
            <BankTransfer
                openModal={openBankTransferModal}
                setOpenModal={setOpenBankTransferModal}
                bankDetails={bankDetails}
                verifyloading={verifyLoading}
                paymentSuccess={paymentSuccess}
                confirmDirectBankTransfer={confirmDirectBankTransfer}
                isConfirmLoading={isConfirmLoading}
                setIsConfirmLoading={setIsConfirmLoading}
            />

            {/* Success Modal */}
            <SuccessPayment
                openModal={openModal}
                setOpenModal={setOpenModal}
                verifyloading={verifyLoading}
                paymentSuccess={paymentSuccess}
            />
        </div>
    );
}
