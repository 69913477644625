import { FC, useEffect, useRef, useState } from 'react';
import { Header, Footer, Modal } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom';
import { RootState } from 'redux/rootReducer';
import productService from 'services/productService';
import { Spinner } from 'components/common/Loader';
import { Radio } from 'antd';
import bookingService from 'services/bookingService';
import RightReservationSection from 'pages/ReusableProduct/RightReservationSection';
import Coupon from './Coupon';
import PaySmallSmall from './PaySmallSmall';
import { UpcomingActivityServices } from 'services/upcomingActivities';
import moment from 'moment'
import { paysmallsmall } from 'redux/reservation/actions';
import { IArtData } from 'pages/Photography/interface';
import { AuxService } from 'services/auxService';
import { Delivery, ICartCheckout } from 'services/auxService/interface';
import RightPhotoSection from 'pages/ReusableProduct/RightPhotoSection';
import { formatPrice } from 'helpers';
import queryString from 'query-string';
import { useToast } from 'components/common/Toast/ToastProvider';

const Payment: FC = () => {
    const { isAuthenticated, user } = useSelector((state: RootState) => state.user);
    const dispatch = useDispatch();
    const [productDetails, setproductDetails] = useState<Record<string, any>>({});
    const [quotes, setQuotes] = useState<Record<string, any>>({});
    const [isLoading, setisLoading] = useState(true);
    const [isLoyalityCodeApplied, setIsLoyalityCodeApplied] =  useState(false)
    const [isDiscountCodeApplied, setIsDiscountCodeApplied] =  useState(false)
    const navigate = useNavigate();
    
    const { detailsID } = useParams<{ detailsID: string }>();

    // const { payment }:any = useSelector((state: RootState) => state.payment);
    const { selectedBooking } = useSelector((state: RootState) => state.reservation);
    const { paysmallsmallCost } = useSelector((state: any) => state.reservation);

    const [installmentNo, setInstallmentNo] = useState(1)
    const [locationStates, setLocationstate] = useState<any>(null)
    const location = useLocation()

    const { bookingType } = queryString.parse(window.location.search);

    const [guestBookkingOnApproval, setGuestBookkingOnApproval] = useState(false)
    const [guestBookingOnApprovalEmail, setGuestBookingOnApprovalEmail] = useState('')

    const [artdata, setArtdata] = useState<IArtData>(location.state)

    const [paymentPlan, setpaymentPlan] = useState([]);
    const [deliveryInfo, setdeliveryInfo] = useState<Delivery[]>();
    const [checkoutdetails, setcheckoutdetails] = useState<ICartCheckout>();
    const [seerbitloading, setSeerbitloading] = useState(false);
    const [ isLoyaltyPayment, setIsLoyaltypayment ] = useState(false);
    const [bankDetails, setBankDetails] =  useState(null);
    const [isConfirmLoading, setIsConfirmLoading] =  useState(false)


    const [searchParams] = useSearchParams();
    const detailID = searchParams.get('detailID');
    const paramGuest = searchParams.get('guest');
    const paramQty = searchParams.get('qty');
    const paramBookingId = searchParams.get('bookingID');
    const paramBookingRef = searchParams.get('bookingRef');

    const [paymentForm, setPaymentForm] = useState({
        bookingID: 0,
        paymentType: 'full',
        paymentPlatform: 'flutterwave',
        couponCode: '',
        totalPayable: 0,
        insuranceCode: '',
        insuranceId: 0,
        userData: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.emailAddress,
            phone: user.phoneNumber,
        },
        isActivityCalender: false,
    });

    const [deliveryData, setdeliveryData] = useState<any>({
        type: 'pickup',
        id: 0,
        address: productDetails.address || artdata?.address || "",
        deliveryFee: 0
    })

    async function getCompleteProductDetails(id: any) {
        setisLoading(true)
        try {
            const res = await productService.getProductDetails(id);
            // console.log(res);
            setproductDetails(res.data['productDetails'])
            setdeliveryData({
                ...deliveryData,
                address: res.data['productDetails']?.address
            })
            setisLoading(false)
        } catch (error: any) {
            setisLoading(false)
            console.log(error);
        }
    }

     //Upload Bank Payment Proof
     const [manualPayment, setManualPayment] = useState(null)
     const [sendingBankTrans, setSendingBankTrans] =  useState(false)
     const [bankTranSucc, setBankTranSucc] =  useState(false)
     const fileInputRef:any = useRef();
     const toast = useToast();
 
     const UploadBankTransferProof =async()=>{
         setSendingBankTrans(true)
         try{
         let data = new FormData();
         data.append('Receipt', manualPayment);
         data.append('BookingID', detailsID)
         const res = await productService.uploadBankTransferProof(data)
         if(res){
             setSendingBankTrans(false)
             setManualPayment(null)
             setBankTranSucc(true)
             toast?.success('Uploaded Successfully.');
         }
         }catch(err){
             setSendingBankTrans(false)
         }
     }

    //Direct Bank Transfer Method
    const getBankTransferDetails =async()=>{
        setSeerbitloading(true)
            const data ={
                amount: isLoyaltyPayment ? quotes?.totalPayable : paymentForm?.totalPayable,
                bookingId: paymentForm?.bookingID,
            }
            try{
            const res = await productService.fetchBankTransferDetails(data)
            setBankDetails(res.data)
            setSeerbitloading(false)
            }catch(err){
                setSeerbitloading(false)
            }
        }

        const confirmDirectBankTransfer =async()=>{
            setIsConfirmLoading(true)
                const data ={
                    paymentReference: bankDetails.reference,
                    bookingId: paymentForm?.bookingID,
                    paymentMethodMode: paymentForm?.paymentType,
                    installment: installmentNo,
                    referralCode:'',
                    isLoyaltyPayment,
                    insuranceId: 0

                }
                try{
                const res = await productService.confirmDirectBankTransfer(data)
                navigate('/checkout-complete/' + detailsID)
                setIsConfirmLoading(false)
                }catch(err){
                    setIsConfirmLoading(false)
                    toast?.error(err.response.data);
                }
            }

    const getBookingByBookingId = async (id: number | string) => {
        setisLoading(true);
        try {
            const res = await bookingService.getBookingByBookingID(id);
            setproductDetails(res.data);
            let nextInstallment: any = res.data?.bookingPaymentSmallSmalls.filter((p: any) => p.paymentStatus != 'Paid')[0]
            let breakDownCost: any = [];
            if (locationStates?.bookingId) {
                breakDownCost = [
                    {
                        name: 'Total Amount Payable',
                        value: nextInstallment?.amountDue,
                    }
                ]

                setPaymentForm({
                    ...paymentForm,
                    totalPayable: nextInstallment?.amountDue,
                    bookingID: res.data.bookingID,
                });

                setQuotes({
                    costBreakDowns: breakDownCost || []
                })
            }
            else {
                setPaymentForm({
                    ...paymentForm,
                    bookingID: res.data.bookingID,
                });
                setQuotes({
                    costBreakDowns: res?.data?.costBreakDowns || []
                })
            }

            setisLoading(false);

            // createBookingQuote(res.data.bookingDetails);
        } catch (err: any) {
            setisLoading(false);
            console.log('err', err.response.data.responseMessage);
        }
    };

    const getActivityBookingByBookingId = async (id: any) => {
        setisLoading(true);
        try {
            const res = await bookingService.getActivityBookingById(Number(id));
            // setproductDetails(res.data);

            setPaymentForm({
                ...paymentForm,
                isActivityCalender: true,
                bookingID: res.data.bookingID,
            });
            setQuotes(res?.data);

            dispatch(
                getBookingByBookingId(res.data)
            );

            setisLoading(false);
            ;
        } catch (err: any) {
            setisLoading(false);
            console.log('err', err.response.data.responseMessage);
        }
    };


    const getActivityById = async (id: number | string) => {
        setisLoading(true);
        try {
            const res = await UpcomingActivityServices.getActivityCalendar(id);

            setproductDetails(res.data);
            setisLoading(false);

            // setPaymentForm({
            //     ...paymentForm,
            //     bookingID: paramBookingId ? parseInt(paramBookingId) : 0,
            //     totalPayable: selectedBooking?.payableAmount,
            // });
        } catch (err: any) {
            setisLoading(false);
            console.log('err', err.response.data.responseMessage);
        }
    };

    const createBookingQuote = async (data: any) => {
        try {
            const res = await bookingService.createQuote({ quoteRequests: data });
            setQuotes(res.data);
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(res.data.totalPayable),
            });
            setisLoading(false);
        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
        }
    };

    async function getCheckoutDetails() {

        try {
            const res = await AuxService.CartCheckout({
                cartIds: artdata.cartIds,
                deliveryId: deliveryData.id,
                deliveryOption: deliveryData.type.toUpperCase(),
                userId: artdata.userId
            })
            const data = res.data as ICartCheckout;
            setcheckoutdetails(data)
            // console.log("cak", data);
            
            setPaymentForm({
                ...paymentForm,
                totalPayable: data.totalAmount,
                userData: {
                    firstName: artdata.details.firstName,
                    lastName: artdata.details.lastName,
                    email: artdata.details.emailAddress,
                    phone: artdata.details.phoneNumber,
                }
            })
        } catch (error) {
            console.log('error');
        }
    }

    async function getDeliveryDetails() {

        try {
            const res = await AuxService.DeliveryDetails(artdata.vendorId || productDetails.vendorId)
            const data = res.data as Delivery[];
            setdeliveryInfo(data);
        } catch (error) {
            console.log('error');
        }
    }


    const formatNumber = (param: any) => {
        const value = Number(param).toFixed(2);
        return Number(value);
    };

    const getPaymentPlan = async () => {
        try {
            const res = await bookingService.getpaymentPlan();
            setpaymentPlan(res.data);
        } catch (err: any) {
            console.log('err', err.response.data.responseMessage);
        }
    };

    function computeTotalAmount() {
        const value = artdata.cart.reduce((acc, item) => acc + (item.amount), 0);
        // setTotalamount(value);
        return value;
    }

    const path = window.location.pathname.toLowerCase();

    const dayDiff = moment(productDetails?.checkInDate).diff(moment(), 'days');

    const resetIsDiscountAndIsLoyalityApplied = async()=>{
        try{
            const data={
                bookingID: detailsID,
                isDiscountCodeApplied: false,
                isLoyaltyCodeApplied: false
            }
            
           const res =  await bookingService.resetisDisountIsLoyalityApplied(data)
        //    res  && console.log('update api on page load', res.responseMessage)
        }catch(err){}
    }

    useEffect(()=>{
        if( !isNaN(paymentForm.totalPayable) && paymentForm.totalPayable){
            // getBankTransferDetails()
        }
    },[paymentForm.totalPayable])

    useEffect(()=>{
        manualPayment !== null && UploadBankTransferProof()
    },[manualPayment])

    useEffect(() => {
        window.scrollTo(0, 0)
        let isCancelled = false;
        if (!isCancelled) {
            getPaymentPlan()
            if (detailsID) {
                getBookingByBookingId(detailsID);
            }
            if (detailID) {
                if (path.includes('activity')) {
                    getActivityBookingByBookingId(paramBookingId)
                    getActivityById(detailID);
                }

                setPaymentForm({
                    ...paymentForm,
                    isActivityCalender: true
                });
                // else {
                //     getExperienceByDetailsId(detailID);
                // }
            }
        }
        return () => {
            isCancelled = true;
            dispatch(paysmallsmall(null))
        };
    }, [detailsID, detailID, locationStates]);

    useEffect(() => {
        // console.log("paymentForm", paymentForm);
        if (paysmallsmallCost?.installmentModels?.length > 0
            && paymentForm?.paymentType == 'installment'
        ) {
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(paysmallsmallCost?.installmentModels[installmentNo - 1]?.amount)
            })
        }
        else if (paymentForm?.paymentType == 'full' && (detailsID || detailID)) {
            setPaymentForm({
                ...paymentForm,
                totalPayable: formatNumber(quotes?.costBreakDowns?.filter(
                    (p: any) => p?.name?.includes('Total Amount Payable'))[0]?.value
                )
            })
        }
        else if (artdata && artdata.isAuxservice) return;
        else if (locationStates?.bookingId) return
        else {
            setPaymentForm({
                ...paymentForm,
                totalPayable: selectedBooking?.payableAmount,
            })
        }

    }, [paysmallsmallCost, paymentForm?.paymentType, paymentForm?.totalPayable, paymentForm.paymentPlatform])

    useEffect(() => {
        const locationState = location?.state?.paymentInfo;
        if (locationState?.bookingId) {
            setLocationstate(locationState)
            setInstallmentNo(locationState?.installment || 1)
            setPaymentForm({
                ...paymentForm,
                paymentType: 'installment',
                bookingID: locationState?.bookingId,
                // totalPayable: locationState?.amountDue || 0
            })
        }

    }, [location])

    useEffect(() => {
        const { address, id, type } = deliveryData

        // console.log(deliveryData);
        

        if (type === "pickup") {
            getCheckoutDetails();
            return;
        }

        if (type === "shipping" && id && address.length > 3) {
            getCheckoutDetails()
            return;
        }

        return;
    }, [deliveryData])

    useEffect(() => {
        if (artdata && artdata.isAuxservice) {
            getCompleteProductDetails(detailsID)
            getDeliveryDetails()
        }
    }, [])

    useEffect(() => {
        resetIsDiscountAndIsLoyalityApplied()
    },[]);

    useEffect(()=>{
        bookingType === 'boa' && setGuestBookkingOnApproval(true)
    },[])

    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <section className="bg-gray-50 py-1">
                        <div className="container mb-6">
                            <div className="flex flex-wrap gap-8 items-center justify-between my-10">
                                <div>
                                    <button
                                        onClick={() => {
                                            resetIsDiscountAndIsLoyalityApplied()
                                            navigate(-1)
                                        }}
                                        className="flex items-center gap-3 border border-neutral108 rounded-md py-2 px-3 text-sm font-semibold text-neutral capitalize "
                                    >
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </span>
                                        Go back
                                    </button>
                                </div>

                                <div className="flex items-center flex-wrap  gap-3">
                                    {path.includes('activit') ? (
                                        <span className="text-sm text-neutral108 font-semibold capitalize">
                                            {productDetails?.activityCalendar?.title}
                                        </span>
                                    ) : (
                                        <Link
                                            to="/user-profile/bookings"
                                            className="text-sm text-neutral108 font-semibold capitalize"
                                        >
                                            {productDetails?.productName}
                                        </Link>
                                    )}
                                    <span className="text-sm font-bold text-neutral108">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                    <Link to="#" className="text-sm text-neutral108 font-semibold capitalize">
                                        Confirm and Pay
                                    </Link>
                                    {/* <span className="text-sm font-bold text-neutral108">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <a
                      href="#"
                      className="text-sm text-neutral108 font-semibold capitalize"
                    >
                      Checkout completed
                    </a> */}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="bg-gray-50 px-4 sm:px-20 lg:px-40 pb-20 md:pb-40 grid lg:grid-cols-2 gap-x-16 gap-y-8">
                        <div className="space-y-10 text-left  ">
                            <h2 className="pb-8 border-b capitalize text-4xl font-semibold ">Confirm and pay</h2>
                            <div className="space-y-8">
                                {
                                    locationStates
                                        ? ''
                                        : (

                                            <div className="space-y-5">

                                                {artdata && artdata.isAuxservice && (
                                                    <div className="space-y-5">
                                                        <h3 className=" text-2xl font-medium mb-4">Delivery Type</h3>

                                                        <Radio.Group
                                                            onChange={(e) =>
                                                                setdeliveryData({
                                                                    ...deliveryData,
                                                                    type: e.target.value,
                                                                    address: e.target.value === 'pickup' ? artdata.address || productDetails.address : ""
                                                                })
                                                            }
                                                            value={deliveryData.type}
                                                        >
                                                            <Radio value="pickup">Pickup</Radio>
                                                            <Radio value="shipping">Shipping</Radio>
                                                        </Radio.Group>

                                                        

                                                        {deliveryData.type === 'shipping' && <div className="border-2 mt-4 rounded-lg p-5">
                                                            <h2 className='flex items-center mb-6 ml-2 text-base font-light text-gray-500'>
                                                                SHIPPING ADDRESS
                                                                <svg width="16" className='ml-2' height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.4133 5.66261C3.72664 -0.110728 12.28 -0.104061 13.5866 5.66927C14.3533 9.05594 12.2466 11.9226 10.4 13.6959C9.75461 14.3181 8.89309 14.6658 7.99664 14.6658C7.10018 14.6658 6.23867 14.3181 5.5933 13.6959C3.7533 11.9226 1.64664 9.04927 2.4133 5.66261V5.66261Z" stroke="#23262F" stroke-width="1.5" />
                                                                    <path d="M6.1665 7.66667L7.1665 8.66667L9.83317 6" stroke="#23262F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </h2>
                                                            <div className="flex flex-wrap items-center gap-4" id="payment-methods-Tab">
                                                                <select id="password" className="block w-full bg-transparent  placeholder:text-sm placeholder:font-medium"
                                                                        required
                                                                        onChange={
                                                                            (e) =>{
                                                                                setdeliveryData({
                                                                                    ...deliveryData,
                                                                                    id: e.target.value,
                                                                                    deliveryFee: deliveryInfo?.filter((p: any) => p?.deliveryDetailsID == e.target.value )[0]?.deliveryPrice || 0
                                                                                })
                                                                            }
                                                                        }
                                                                        value={deliveryData.id}
                                                                    >
                                                                        <option className="text-sm font-medium"
                                                                                    value=""
                                                                                >
                                                                                    - select location -
                                                                                </option>

                                                                    {
                                                                        deliveryInfo.map(d => {
                                                                            return <>
                                                                                <option className="text-sm font-medium"
                                                                                    value={d.deliveryDetailsID}
                                                                                >
                                                                                    {`${d.deliveryLocation} - N${formatPrice(d.deliveryPrice)}`}
                                                                                </option>

                                                                            </>
                                                                        })
                                                                    }
                                                                </select>

                                                            </div>
                                                        </div>}

                                                        {deliveryData.type === 'shipping' && <div>
                                                            <label
                                                                htmlFor=""
                                                                className="block mb-2 text-xs font-bold uppercase text-neutral108"
                                                            >
                                                                Address
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id=""
                                                                className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-medium"
                                                                placeholder='Enter your adddress'
                                                                required
                                                                value={deliveryData.address}
                                                                onChange={(e: any) => setdeliveryData(
                                                                    { ...deliveryData, address: e.target.value }
                                                                )}
                                                            />
                                                        </div>}
                                                    </div>
                                                )}

                                                <h3 className=" text-2xl font-medium mb-4">Choose a payment plan</h3>

                                                <Radio.Group
                                                    onChange={(e) =>
                                                        setPaymentForm({
                                                            ...paymentForm,
                                                            paymentType: e.target.value,
                                                        })
                                                    }
                                                    value={paymentForm.paymentType}
                                                >
                                                    <Radio value="full" className=' !accent-red-900'>Full payment</Radio>
                                                    {path.includes('activity')
                                                        ? ''
                                                        : !user.userID ? '' 
                                                        : <Radio
                                                            disabled={
                                                                (quotes?.totalPayable < 50000)
                                                                || (quotes?.costBreakDowns
                                                                    ?.filter((p: any) =>
                                                                        p?.name == "Total Amount Payable")[0]?.value < 50000
                                                                )
                                                                || (dayDiff < 14)
                                                            }
                                                            value="installment">Aura Flex</Radio>
                                                    }
                                                </Radio.Group>
                                            </div>
                                        )
                                }

                                {
                                    paymentForm.paymentType == 'installment' && !locationStates
                                    && (
                                        <PaySmallSmall
                                            bookingId={detailsID || 0}
                                            discountCode={paymentForm.couponCode}
                                            isLoyaltyCodeApplied={isLoyalityCodeApplied}
                                            isDiscountCodeApplied={isDiscountCodeApplied}
                                            isExperience={
                                                productDetails?.serviceID == 0
                                                && productDetails?.productDetailsID >= 0
                                                // && productDetails?.bookingType?.toLowerCase()?.includes('experience')
                                            }
                                            paymentPlan={paymentPlan}
                                        />
                                    )
                                }

                                {paymentForm.paymentType && (
                                    <div className="space-y-5">
                                        <h3 className=" text-2xl font-medium mb-4">Pay with</h3>

                                        <Radio.Group
                                            onChange={(e) =>
                                                setPaymentForm({
                                                    ...paymentForm,
                                                    paymentPlatform: e.target.value,
                                                })
                                            }
                                            value={paymentForm.paymentPlatform}
                                        >
                                            <Radio value="flutterwave">Flutterwave</Radio>
                                            <Radio value="seerbit">Seerbit</Radio>
                                            {/* <Radio value="redpay">Redpay</Radio> */}
                                            <Radio value="transfer" onClick={()=>fileInputRef.current.click()}>Upload Payment Details</Radio>
                                            <input
                                                id="file1"
                                                type="file"
                                                className="hidden"
                                                ref={fileInputRef}
                                                accept='.jpg, .png, .jfif, .jpeg, '
                                                onChange={(e) => setManualPayment(e.target.files[0])}
                                                />
                                        </Radio.Group>
                                        {sendingBankTrans &&
                                            <p className='mt-4 text-auraPrimary'>Uploading proof of payment...</p>
                                        }
                                    </div>
                                )}

                                <div id="payment-methods-tab-content">
                                    {/* {isPayWith === 'BankTransfer' && <BankTransfer />}
                                        {isPayWith === 'Credit/DebitCard' && <CreditDebitCard />}
                                        {isPayWith === 'Paysmallsmall' && <PaySmallSmall />} */}
                                    {!isLoading && (
                                        <Coupon
                                            payment={paymentForm}
                                            setPaymentForm={setPaymentForm}
                                            isLoading={isLoading}
                                            paramBookingId={paramBookingId}
                                            paramBookingRef={paramBookingRef}
                                            detailID={detailID}
                                            paramQty={paramQty}
                                            getBookingByBookingId={getBookingByBookingId}
                                            setQuotes={setQuotes}
                                            installmentNo={installmentNo}
                                            locationStates={locationStates}
                                            isExperience={
                                                productDetails?.serviceID == 0
                                                && productDetails?.productDetailsID >= 0
                                                && productDetails?.productID == 33
                                                // && productDetails?.bookingType?.toLowerCase()?.includes('experience')
                                            }
                                            artdata={artdata}
                                            checkoutdetails={checkoutdetails}
                                            deliveryData={deliveryData}
                                            productDetails={productDetails}
                                            setproductDetails={setproductDetails}
                                            setIsLoyalityCodeApplied={setIsLoyalityCodeApplied}
                                            setIsDiscountCodeApplied={setIsDiscountCodeApplied}
                                            setIsLoyaltypayment={setIsLoyaltypayment}
                                            isLoyaltyPayment={isLoyaltyPayment}
                                            setSeerbitloading={setSeerbitloading}
                                            seerbitloading={seerbitloading}
                                            bankDetails={bankDetails}
                                            confirmDirectBankTransfer={confirmDirectBankTransfer}
                                            isConfirmLoading={isConfirmLoading}
                                            setIsConfirmLoading={setIsConfirmLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {!artdata && 
                        <RightReservationSection 
                            productDetails={productDetails} 
                            setproductDetails={setproductDetails}
                            quotes={quotes} 
                            locationStates={locationStates} 
                        />}

                        {artdata && artdata.isAuxservice && <RightPhotoSection
                            productDetails={productDetails}
                            artdata={artdata}
                            checkoutdetails={checkoutdetails}
                            deliveryData={deliveryData}
                        />}

            {guestBookkingOnApproval && 
                <div
                    className="fixed inset-0 flex z-50 items-center justify-center  bg-black bg-opacity-75 modal"
                    id="successModal"
                    >
                    <div className="relative max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:w-[30%]">
                    <div className="p-3 text-center md:p-8">
                      <h2 className="text-3xl font-bold mb-2 mt-3">
                          Guest Reservation
                      </h2>
                      <p className="py-3 text-lg text-neutral">
                        Kindly validate your email address
                      </p>
                    
                      <div className="mb-6 col-span-2 mt-2">
                            <label htmlFor="email" className="block mb-2 text-xs font-bold uppercase text-neutral108">
                                Email Address <span className='text-red-500'>*</span>
                            </label>
                            <input
                                type="text"
                                id="email"
                                name="email"
                                onChange={(e)=> setGuestBookingOnApprovalEmail(e.target.value)}
                                className="block w-full border border-gray-300 rounded-lg shadow-sm placeholder:text-sm placeholder:font-light"
                                placeholder="Enter email address"
                                required
                            />
                        </div>
                      <div className=" flex justify-evenly my-8">
                        <button
                            onClick={()=>{
                                if(guestBookingOnApprovalEmail){
                                localStorage.setItem('guestEmail', guestBookingOnApprovalEmail)
                                setGuestBookkingOnApproval(false)
                                }
                            }}
                          className="block w-32 px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-orange-600 my-btn"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                }

                {bankTranSucc &&
                <div
                    className="fixed inset-0 flex z-50 items-center justify-center  bg-black bg-opacity-75 modal"
                    id="successModal"
                    >
                    <div className="relative max-w-xl px-3 py-2 bg-white shadow-xl rounded-xl lg:w-[30%]">
                    <div className="p-3 text-center md:p-8">
                      <h2 className="text-3xl font-bold mb-2 mt-3">
                          Bank Transfer
                      </h2>
                      <p className="py-3 text-lg text-neutral">
                        Proof of payment successfully uploaded!
                      </p>
                      <div className=" flex justify-evenly my-4">
                        <button
                            onClick={()=>{navigate('/user-profile/bookings')}}
                          className="block w-32 px-4 py-3 text-base text-white btn bg-auraPrimary hover:bg-orange-600 my-btn"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                }
                    </div>
                </>
            )}
            <Footer />
        </>
    );
};

export default Payment;
